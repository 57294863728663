import { useAuth } from "~/stores/auth";

export const useApi = async (path, options = {}) => {
  const auth = useAuth();
  const runtimeConfig = useRuntimeConfig();

  useBugsnag().leaveBreadcrumb(path, options, "request");

  try {
    return await $fetch(
      runtimeConfig.public.apiUrl + "/api/v1/terminal" + path,
      {
        ...options,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + auth.token,
          "Software-Version": runtimeConfig.public.appVersion,
          "Employee-Session": auth.employeeSession?.id,
          ...options.headers,
        },
      },
    );
  } catch (error) {
    useBugsnag().leaveBreadcrumb(
      error.data?.error || "UNKNOWN_ERROR",
      { status: error.status, data: error.data },
      "request",
    );

    throw error;
  }
};
